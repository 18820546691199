
bg2{
	background-color: #fff33d;
}

label {
/*  color: #6b7c93; */
  color: #000;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.textlines {
    border: 2px solid #0a0;  /* 枠線 */
    border-radius: 0.3em;   /* 角丸 */
    padding: 0.3em;          /* 内側の余白量 */
    background-color: snow;  /* 背景色 */
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  width: 300px;
  padding: 10px 14px;
  font-size: 1em;
/*  font-family: "Source Code Pro", monospace; */
  font-family: sans-serif, serif;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
